
  
  

  
  ::-webkit-scrollbar {
    width: 1.3rem;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #ffffff;
    transition: all 0.5s ease-in-out;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #222224;
  }
  
  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }
  
 
  
  .project-container {
    max-width: 100%;
    padding: 4rem 1rem;
    margin: 0 auto;
  }
  
  .heading {
    padding: 1rem 0;
    font-size: 3.5rem;
    text-align: center;
  }
  
  .swiper_container {
    height: 52rem;
    padding: 2rem 0;
    overflow: visible !important;
    
  }
  
  .swiper-slide {
    width: 37rem;
    height: 42rem;
    display: flex;
    
  }
  
  @media (max-width: 500px) {
    .swiper_container {
      height: 47rem;
    }
    .swiper-slide {
      width: 28rem !important;
      height: 36rem !important;
    }
    .swiper-slide img {
      width: 28rem !important;
      height: 36rem !important;
    }
  }
  
  
  .swiper-slide img {
    width: 37rem;
    height: 42rem;
    border-radius: 2rem;
    object-fit: cover;
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  
  .slider-controler {
    position: relative;
    
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  
  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }
  
  .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .slider-controler .slider-arrow ion-icon {
    font-size: 80px;
    color: #ffffff;
  }
  
 
  
  .swiper-pagination {
    position: relative !important;
    width: 15rem !important;
    bottom: 1rem;
  }
  
  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(0, 66, 231, 0.1));
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
    background-color: rgb(7, 203, 252);
  }
.vid2{
  display:flexbox;
  height: 200px;
  width: 100%;
}
  .vid1{
    width: 100%;
    display: flex;
    height: 200px;
  }

  .swiper-3d .swiper-slide {
    
    justify-content: center;
    /* justify-items: center; */
    height: max-content;
    flex-direction: column;
    
  }
  .swiper-3d .swiper-slide, .swiper-3d .swiper-cube-shadow{
    max-width: fit-content;
  }

  .carousel{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: fit-content;
    background-color: rgb(18, 24, 53);
    height:max-content;
    font-family: sans-serif;
    box-shadow: 5px 5px 5px 5px  rgba(0, 0, 0, 0.37);
    
  }

  .proj-title{
    display: flex;
    justify-content: center;
    font-size: 30px;
    padding-top: 20px;
    color: rgb(157, 173, 249);
  }

  .proj-description{
    font-style: sans-serif;
    max-width:400px;
    font-size: 20px;
    font-weight: lighter;
    display: flex;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: rgb(155, 161, 162);;
  }
.button2{
    width: 200px;
    height: 60px;
    border-radius:8px;
    border-style: solid;
    border-width: 3px;
    background-color: rgba(0, 255, 234, 0);
    color: rgb(10, 238, 181);
    border-color: rgb(10, 238, 181);
    transition-duration: 0.5s;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top:20px;
    margin-bottom:10px;
    font-family: 'Nanum Gothic Coding';

}
.button2:hover{
    background-color: rgba(10, 240, 163, 0.232);
    cursor: pointer;
  }

  a{
    text-decoration: none;
  }

  .swiper-wrapper{

    height:630px !important;
  }

  .swiper-button-prev:after, .swiper-button-next:after{
    color:rgb(10, 238, 181);
  }
