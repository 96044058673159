.navbar {
  
  
  display: flex;
  justify-content:right;
  align-items: center;
  justify-items: center;
  padding: 10px 20px;
  padding-left: 0px;
  
  
  font-size: 15px;
  font-family:sans-serif;
  font-weight: lighter;
  margin-top: 20px;
  
  
  
}


.nav-container{
  background: rgba(2, 0, 36, 0.705);
   
  backdrop-filter: blur(5px);
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index:9999;
  height: 100px;
  
}




.navbar-toggle {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  transition: color 0.3s ease;
  margin-left: auto;
}
.navbar-toggle.open {
  
  transform: rotate(180deg);
}
.navbar-toggle.open {
  color: rgb(0, 255, 234); 
  transform:rotate(720deg);
  transition: 3s;
  

}

@media (min-width: 768px) {
    .navbar-toggle {
      display: none;
    }
  }
.navbar-toggle:hover{
  color: rgb(0, 255, 234);
}


.navbar-links {
  list-style: none;
  display: flex;
  gap: 35px;
  justify-content:right;
}
.navbar-links {
  align-items: center;

}
.navbar-links li {
  margin: 0;
  font-family: 'Nanum Gothic Coding';
  
}

.navbar-links a {
  font-weight: bold;
  color: rgb(7, 203, 252);
  text-decoration: none;
  
}
.navbar-links a:hover {
  color: rgb(0, 255, 234);
  }

@media (max-width: 768px) {
  .navbar-links {
    opacity: 0;
    overflow: hidden;
    transition: height 2s ease, opacity 1s ease;
    transform:translate(-100%);
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height:300px;
    backdrop-filter: blur(50px);
  }

  .navbar-links.open {
    opacity: 1;
    transform: translateY(0);
    display: flex;
    justify-content: space-between;
    background: rgb(2,0,36);
    background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(18,24,53,1) 28%, rgba(19,111,130,1) 95%);
    backdrop-filter: blur(50px);
    
   
  }
  .navbar-links.closed{
    pointer-events: none;
  }
  
  

  .navbar-links.open li{
    margin-left:20px ;
    
    background: none;
  }
  .resume-button.open{
    margin-right: 0;
  }
}

.resume-button{
  background-color: rgba(0, 255, 234, 0);
  color: rgb(10, 238, 181);
  padding: 5px;
  border-radius:8px;
  border-color: rgb(10, 238, 181);
  border-style: solid;
  border-width: 1px;
  height: 40px;
  width: 80px;
  font-family: 'Nanum Gothic Coding';
  font-size: 15px;
  margin-right: 20px;
}

.resume-button:hover{
  background-color: rgba(10, 240, 163, 0.232);
  cursor: pointer;;
}


a.nav-logo{
  width: 50px;
  height: 50px;
  align-items: left;
  margin-left:10px ;
  display: flex;
  top: 0;
  margin-bottom: 0;
  margin-right: auto;
  margin-left:50px;
}


a.nav-logo:hover{
  transform: scale(1.1);
}

.navbar{
  transition:transform 0.3s ease;
}

