.emailForm {
    display: flex;
    flex-direction: column;
  }
  
  input,
  textarea {
    margin: 10px 0px;
  }

  .form-card{
    margin-top: 30px;
    margin-bottom:140px;
  }

  .email-name{
    border-radius:8px;
    border-style: solid;
    border-width: 0.9px;
    background-color: rgba(0, 255, 234, 0);
    color: rgb(10, 238, 181);
    border-color: rgb(10, 238, 181);
    height: 50px;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: lighter;
    padding-left: 20px;
  }

  .email-email{
    border-radius:8px;
    border-style: solid;
    border-width: 0.9px;
    background-color: rgba(0, 255, 234, 0);
    color: rgb(10, 238, 181);
    border-color: rgb(10, 238, 181);
    height: 50px;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: lighter;
    padding-left: 20px;
  }

  .email-msg{
    border-radius:8px;
    border-style: solid;
    border-width: 2px;
    background-color: rgba(0, 255, 234, 0);
    color: rgb(10, 238, 181);
    border-color: rgb(10, 238, 181);
    padding-left: 20px;
    font-size: 15px;
    
  }

  .email-btn{
    border-radius:8px;
    border-style: solid;
    border-width: 1px;
    background-color: rgba(0, 255, 234, 0);
    color: rgb(10, 238, 181);
    border-color: rgb(10, 238, 181);
    height: 40px;
    font-size: 20px;
    font-family: 'Nanum Gothic Coding';
  }

  .email-btn:hover{
    background-color: rgba(10, 240, 163, 0.232);
    cursor: pointer;
  }

  .email-btn:disabled {
    background-color: rgba(177, 174, 174, 0.24);
    cursor: not-allowed;
  }



  .notification {
    margin: 10px 0;
    padding: 10px;
    color: white;
    background-color: rgba(0, 128, 0, 0.712);
    text-align: center;
    border-radius: 5px;
    font-family: sans-serif;
  }
  
  .notification.error {
    background-color: rgba(255, 0, 0, 0.712);
    font-family: sans-serif;
  }