.App{
    overflow: hidden;
   
    margin: 0;
    padding: 0%;
    
    
}

.others{
    z-index: -9999;
}

*{
    margin: 0;
    padding: 0%;
    
  }

  body{
    background: rgb(2,0,36);
   
  }