.experiences-card{
    padding-bottom: 20px;
    font-family:sans-serif;
    display: flex;
    max-width: min-content;
}
.desc{
    padding-bottom:10px;
    max-width: 650px;
}
.MuiCard-root {
    margin-bottom: 30px;
    
}   
.all-about{
    margin-top: 230px;


}

.tabs{
    color: aliceblue;
}

.all-cards{
    padding-top: 20px;
    font-family: sans-serif;
    font-weight: lighter;
    color: rgb(155, 161, 162);
}

.all-cards h1{
    color: rgb(157, 173, 249);
}


.company-name{
    font-size: 17px;
    display: flex;
    flex-direction: row;
    color: rgb(207, 211, 211);
    padding-bottom: 10px;
}

.date{
    margin-left: 10px;
    color: rgb(227, 225, 225);
}

.experience-description{
    color: rgb(155, 161, 162);
    font-size:17px;
}

.MuiPaper-root {
    
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    background-color: rgba(10, 17, 109, 0) !important;
    padding: 10px;
}

.MuiPaper-elevation1 {
    box-shadow: 0px 5px 4px 1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 5px -1px 3px 0px rgba(0,0,0,0.12) !important;
}

.MuiPaper-rounded {
    border-radius: 14px;
}




/* Experience.css */

.tabs-content {
    display: flex;
    align-items: center;
}



.experiences-cards {
    margin-left: 20px; /* Adjust this margin as needed */
    margin-bottom: 20px; /* Adjust this margin as needed */
}

/* Add any other styles as needed */
.tabs-vertical {
    display: flex;
    flex-direction: column;
}

.tabs-horizontal {
    display: flex;
    flex-direction: row;
}

@media (max-width: 900px) {
    .tabs-horizontal {
        flex-direction: row;
        width: 100%;
    }

    .tabs-horizontal .MuiTabs-scroller {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: center;
    }

    .tabs-horizontal .MuiTab-root {
        flex: 1;
        text-align: center;
    }

    .tabs-content {
        display: flex;
        flex-direction: column;
    }

    .tabs-container {
        order: -1; /* Move tabs to the top */
    }
}
