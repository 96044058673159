

.about-component{
    
    padding-top: 200px;

   
    

}

.about-title{
    color:rgb(10, 238, 181);
    display: flex;
    font-size: 20px;
    font-weight: lighter;

    padding-bottom: 20px;
    font-family: 'Nanum Gothic Coding';
}

.about-component h1{
    font-size: 65px;
    padding-bottom: 20px;
    color:rgb(157, 173, 249);
    display: flex;
    font-family:sans-serif;
    font-weight: light;
    
}
.about-component h2{
    font-size: 70px;
    font-family:sans-serif;
    font-weight: light;
    color: rgb(124, 132, 172);
    padding-bottom: 40px;
}

.about-me{
    max-width: 500px;
    font-family:sans-serif;
    font-weight:lighter;
    font-size: large;
    color: rgb(155, 161, 162);
}


.name{
    color:rgb(10, 238, 181);
    display: flex;
}